export class Users {
    firstName : any = '';
    lastName: any = '';
    email: string = '';
    organisation: any = '';
    org: any = '';
    reportsTo : string = '';
    reportedFirstname: string = '';
    reportedLastname: string = '';
    password: string = '';
    type: string = '';
    id: string = '';
    _id: string = '';
    phone: string = '';
    status: boolean = true;
    Isreadonly: boolean = false;
    createDate: string = '';
    termsFlag: string = '';
    firstTimeUse!: boolean;
    resetPasswordToken: string | undefined;
    resetPasswordExpires!: Date;
}

