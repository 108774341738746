

export class Organisation {
    name : string = '';
    firstName: string = '';
    lastName: string = '';
    status!: boolean ;
    contactPerson: string = '';
    email: string = '';
    id: string = '';
    _id: string = '';
    contactEmail: string = '';
    logoSquare: string = '';
    logoHorizontal: string = '';
    updateStatus!: boolean;
    isSelected!: boolean
}




